export function compareDatesWithoutTime(date1:any, date2:any) {
    var d1 = new Date(date1);
    d1.setHours(0, 0, 0, 0);
    var d2 = new Date(date2);
    d2.setHours(0, 0, 0, 0);

    if (d1.getTime() === d2.getTime()) {
        return 0;     } 
        else if (d1.getTime() < d2.getTime()) {
        return -1; 
    } else {
        return 1; 
    }
}
