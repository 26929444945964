export enum RouteType {
  HOME = "/",
  CREATE_ACCOUNT = "/onboarding/create-account",
  VERIFY_PHONE_CODE = "/onboarding/verify-phone-code",
  VERIFY_EMAIL_CODE = "/onboarding/verify-email-code",
  ACCOUNT_DETAILS = "/onboarding/account-details",
  MERCHANT_DETAILS = "/onboarding/merchant-details",
  ONBOARDING_SUCCESS = "/onboarding/success",
  SIGN_IN = "/sign-in",
  RESET_PASSWORD = "/reset-password",
  RESET_PASSWORD_SUCCESS = "/reset-password/success",
  SUBMIT_RESET_PASSWORD = "/forgot-password/verify",
  TRANSACTION_DETAIL = "/transaction-detail",
  P2P_TRANSACTION = "/m2c-transaction",
  ADMIN = "/settings",
  ADMIN_USERS = "/settings/users",
  ADMIN_USERS_INVITE = "/settings/users/invite",
  ADMIN_API_KEYS = "/settings/api-keys",
  ADMIN_API_KEYS_CREATE = "/settings/api-keys/generate",
  ACCEPT_INVITE = "/accept-invite",
  CLOSING_SALES = "/closing-sales",
  TRANSACTIONS = "/transactions",
  INVOICE = "/invoice",
  RequestTransaction = "/request-transaction",
  REFUND_DISPUTE = "/refund-dispute",
  REFUND_REQUEST_ACCEPTANCE = "/refund-request-acceptance",
  REFUND_REQUEST_APPROVAL = "/refund-request-approval",
  ANALYTICS = "/analytics",
  SETTINGS = "/settings",
  CHANGE_PASSWORD = "/changepassword",
  CHANGE_PASSWORD_SUCCESS = "/changepassword/success",
  CUSTOMER_MANUAL_VERIFICATION_FORM = "/customermanualverificationform",
  REFUND_FORM_TOKEN = "/:id",
  ATM = "/atm",
  ATM_ENTRY = "/atm/entry",
  ATM_ENTRY_QR_CODE = "/atm/entry/qrcode",
  ATM_DISBURSEMENT = "/atm/entry/disbursement",
  AGGREGATOR_MANAGEMENT = "/admin/aggregator-management",
  ROLE_MANAGEMENT = "/admin/role-management",
  USER_MANAGEMENT = "/admin/user-management",
  AGGREGATOR_REGISTER = "/admin/aggregator-register",
  AGGREGATOR_UPDATE = "/admin/aggregator-update",
  INSTITUTION_LIST = "/admin/institution-management",
  INSTITUTION_REGISTER = "/admin/institution-register",
  MERCHANT_GROUP_REGISTER = "/admin/merchant-group-register",
  MERCHANT_GROUP_LIST = "/admin/merchant-group-list",
  MERCHANT_REGISTER = "/admin/merchant-register",
  MERCHANT_LIST = "/admin/merchant-list",
  SUB_MERCHANT_REGISTER = "/admin/sub-merchant-register",
  SUB_MERCHANT_LIST = "/admin/sub-merchant-list",
  OUTLET_LIST = "/admin/outlet-list",
  POS_LIST="/admin/pos-list",
  OUTLET_ = "/admin/outlet-list",
  OUTLET_REGISTER = "/admin/outlet-register",
  OUTLET_EDIT = "/admin/outlet-edit",
  POS_REGISTER = "/admin/pos-register",
  POS_EDIT = "/admin/pos-edit"


}

